import React from "react"
import bedImg from "../assets/imgs/bed.svg"
import bedSingleImg from "../assets/imgs/bed-single.svg"
import couchImg from "../assets/imgs/couch.svg"
import guestsImg from "../assets/imgs/guests.svg"
import { useIntl, Link } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const JedinicaCard = props => {
  const intl = useIntl()
  const room = props.soba
  const slika = props.slika[0]

  return (
    <Link to={`/jedinica-${room.id}`} className="jedinica-container">
      <div className="jedinica-card">
        <div className="image-container">
          <Img
            fluid={{
              ...slika.node.childImageSharp.fluid,
              sizes: "(min-width: 768px) 400px, 100vw",
              aspectRatio: 1.503759398496241,
            }}
          />
        </div>
        <div className="data row">
          {room.beds["bracni"] ? (
            <div>
              <p>{room.beds["bracni"]}</p>
              <img src={bedImg} alt="number of beds" />
            </div>
          ) : (
            ""
          )}
          {room.beds["single"] ? (
            <div>
              <p>{room.beds["single"]}</p>
              <img src={bedSingleImg} alt="number of sinle beds" />
            </div>
          ) : (
            ""
          )}
          {room.beds["kauc"] ? (
            <div>
              <p>{room.beds["kauc"]}</p>
              <img src={couchImg} alt="number of couches" />
            </div>
          ) : (
            ""
          )}
          <div>
            <p>{room.guests}</p>
            <img src={guestsImg} alt="max guests" />
          </div>
        </div>
        <div className="naslov" style={{ fontWeight: "bold" }}>
          <p>{intl.formatMessage({ id: `jedinica${room.id}.naslov` })}</p>
        </div>
      </div>
    </Link>
  )
}

export default JedinicaCard
